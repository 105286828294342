<template>
  <div class="content-wrapper">
    <bo-page-title :no-add="true" />
    <div class="content pt-0">
      <b-card v-if="isList" no-body>
        <b-card-header>
          <b-row>
            <b-col md="auto">
              <b-form-group>
                <button class="btn btn-light" @click="apiGet" v-b-tooltip.hover.noninteractive="'Muat Ulang'" ><i class="icon-spinner11"></i></button>
              </b-form-group>
            </b-col>
            <b-col lg="7">
              <b-form-row>
                <div class="col-md-4">
                  <div class="form-group mb-0">
                    <div class="input-group">
                        <date-range-picker
                            ref="picker"
                            :locale-data="datePickerConfig.locale"
                            :autoApply="datePickerConfig.autoApply"
                            v-model="dateRange"
                            placeholder="Pilih Rentang Tanggal"
                            :opens="'right'"
                            :ranges="datePickerConfig.ranges"
                            @update="updateValues"
                        >
                            <template v-slot:input="picker">
                              <template v-if="!picker.startDate && !picker.endDate">
                                Pilih Rentang Tanggal
                              </template>
                              <template v-else>
                                {{ picker.startDate | date }} - {{ picker.endDate | date }}
                              </template>
                            </template>
                        </date-range-picker>
                        <div class="input-group-append calendar-group">
                            <span class="input-group-text" id="basic-addon2"><i class="icon-calendar"></i></span>
                        </div>
                    </div>
                  </div>
                </div>
              </b-form-row>
            </b-col>
            <div class="col-lg-4">
                <div class="form-group form-group-feedback form-group-feedback-left mb-0 d-flex">
                    <input class="form-control"
                        v-model="filter.search"
                        @input="doSearch"
                        placeholder="Ketik Nama/No. RM Pasien"
                    />
                    <div class="form-control-feedback">
                        <i class="icon-search4 text-indigo"></i>
                    </div>
                        
                    <b-button
                        class="ml-1 d-inline-flex align-items-center"
                        variant="outline-success"
                        id="resetBtn"
                        @click="doResetData()"
                    >Reset</b-button>
                </div>
            </div>
          </b-row>
          <b-row class="mt-2">
            <b-col md="3">
              <b-form-group>
                <v-select placeholder=" -- Pilih Status -- " @input="doFill" v-model="filter.status" :options="mrStatus" label="label" :clearable="true" :reduce="v=>v.value"></v-select>
              </b-form-group>
            </b-col>
            <b-col lg="3">
              <b-form-group>
                <v-select placeholder=" -- Pilih Dokter -- " @input="doFill" v-model="filter.dpjp" :options="mrDokter" label="label" :clearable="true" :reduce="v=>v.value"></v-select>
              </b-form-group>
            </b-col>
            <b-col lg="3">
              <b-form-group>
                <v-select placeholder=" -- Pilih Ruangan -- " @input="doFill" v-model="filter.bangsal" :options="mrBangsal" label="label" :clearable="true" :reduce="v=>v.value"></v-select>
              </b-form-group>
            </b-col>
            <b-col lg="3">
              <b-form-group>
                <v-select placeholder=" -- Pilih Kelas -- " @input="doFill" v-model="filter.kelas" :options="mrKelas" label="label" :clearable="true" :reduce="v=>v.value"></v-select>
              </b-form-group>
            </b-col>
          </b-row>

        </b-card-header>
        <b-card-body class="p-0">
          <b-table
            :fields="fields"
            :items="dataList||[]"
            :per-page="perPage"
            :primary-key="idKey"
            :busy="!dataList"
            responsive
            show-empty
            striped
          >
            <template #empty>
              <div class="text-center">
                <h4 align="center"><span v-if="filter.status || filter.search">Hasil pencarian tidak ditemukan</span>
                <span v-else>Belum Ada Data {{ pageTitle }} yang terdaftar</span></h4>
              </div>
            </template>
            <template #table-busy>
              <div class="skeletal-comp mb-2"></div>
              <div class="skeletal-comp mb-2"></div>
              <div class="skeletal-comp"></div>
            </template>

            <template #cell(number)="v">
              {{(data.per_page*(data.current_page-1))+v.index+1}}
            </template>

            <template #cell(aranr_reg_code)="v">
              <strong>{{ v.value }}</strong>
            </template>

            <template #cell(ap_fullname)="v">
              <div>{{v.item.ap_fullname}} <br> {{v.item.ap_nik}}</div>
            </template>

            <template #cell(no_rm)="v">
              <strong>{{ v.value }}</strong>
            </template>

            <template #cell(ruangan)="v">
              <div class="font-weight-semibold">{{v.item.kamar}}</div>
              <small><span>{{v.item.bangsal}}</span> - <span>{{v.item.kelas}}</span></small>
            </template>

            <template #cell(aranr_reg_date)="v">
              <template v-if="v.value">
                {{ v.value | moment('DD MMM YYYY, HH:mm') }}
              </template>
              <template v-else>-</template>
            </template>
            
            <template #cell(aranr_keluar_ranap_at)="v">
              <template v-if="v.value">
                {{ v.value | moment('DD MMM YYYY, HH:mm') }}
              </template>
              <template v-else>-</template>
            </template>

            <template #cell(status)="v">
              <div :class="['badge', v.item.status_color, 'mb-1']">
                {{ v.value }}
              </div>
            </template>
            
            <template #cell(action)="v">
              <a href='javascript:;' @click="openModalDokumen(v.item)" v-b-tooltip.hover.noninteractive="'Lihat Dokumen'" class="btn btn-icon rounded-round btn-sm alpha-info border-info">
                <i class="icon-file-eye"></i>
              </a>
            </template>
            
          </b-table>
        </b-card-body>
        <b-card-footer class="pagination-custom" v-if="(dataList||[]).length&&data.total>data.per_page">        
          <b-pagination
            class="mb-0"
            v-model="pageNo"
            :per-page="data.per_page"
            :total-rows="data.total"
          />
        </b-card-footer>
      </b-card>
    </div>

    <b-modal id="modal-dokumen" title="Dokumen" size="xl" :ok-only="true" ok-title="Tutup">
      <b-overlay :show="loadingDokumen">
        <b-table-simple responsive striped>
          <b-thead>
            <b-tr>
              <b-th>No</b-th>
              <b-th>Nama</b-th>
              <b-th>Tanggal Pembuatan</b-th>
              <b-th>PPA/Nama</b-th>
              <b-th>File</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <template v-if="dokumen.length">
              <b-tr v-for="(value, index) in dokumen" :key="index">
                <b-td>{{ index + 1 }}</b-td>
                <b-td>{{ value.name }}</b-td>
                <b-td>{{ value.date | moment('DD MMMM YYYY, HH:mm') }}</b-td>
                <b-td>{{ value.ppa_level }}/{{ value.ppa_name }}</b-td>
                <b-td>
                  <a href="#" @click="doDownload(value)" v-b-tooltip.hover.noninteractive="'Download File'" class="btn btn-icon rounded-round btn-sm alpha-success border-success text-success-800">
                    <i class="icon-download"></i>
                  </a>
                </b-td>
              </b-tr>
            </template>
            <template v-else>
              <b-tr>
                <b-th class="text-center" colspan="5">Tidak Terdapat Dokumen</b-th>
              </b-tr>
            </template>
          </b-tbody>
        </b-table-simple>
      </b-overlay>
    </b-modal>
  </div>
</template>

<script>
import GlobalVue from '@/libs/Global.vue'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import Gen from '@/libs/Gen.js'

const _ = global._
const moment = require('moment')

export default {
  extends: GlobalVue,
  components:{DateRangePicker},
  data() {
    return {
        idKey:'aranjo_id',
        statusKey: 'aranjo_terlaksana',
        loadingDokumen: true,
        datePickerConfig: {
            startDate: new Date(),
            endDate: new Date(),
            autoApply: true,
            ranges: {
                'Hari Ini': [new Date(), new Date()],
                '7 Hari Terakhir': [new Date(moment().subtract(6, 'days')), new Date()],
                '30 Hari Terakhir': [new Date(moment().subtract(29, 'days')), new Date()],
            },
            applyClass: 'btn-sm btn-primary',
            cancelClass: 'btn-sm btn-light',
            locale: {
                applyLabel: 'Terapkan',
                cancelLabel: 'Batal',
                direction: 'ltr',
                format: 'mm/dd/yyyy',
                separator: ' - ',
            }
        },
        dateRange: {
            startDate: null,
            endDate: null,
        },
        mrStatus: [],
        mrDokter: [],
        mrBangsal: [],
        mrKelas: [],
        fields: [
            {
                key: 'number',
                label: '#',
            },
            {
                key: 'aranr_reg_code',
                label: 'No. Kedatangan',
            },
            {
                key: 'ap_fullname',
                label: 'Nama / NO KTP',
            },
            {
                key: 'nama_dokter',
                label: 'Dokter DPJP',
            },
            {
                key: 'no_rm',
                label: 'NO RM',
            },
            {
                key: 'ruangan',
                label: 'Ruang & Kamar',
            },
            {
                key: 'aranr_reg_date',
                label: 'Tanggal Masuk',
            },
            {
                key: 'aranr_keluar_ranap_at',
                label: 'Tanggal Keluar',
            },
            {
                key: 'status',
                label: 'Status',
            },
            {
                key: 'action',
                label: 'Aksi',
            },
        ],
        dokumen: []
    }
  },
  computed:{
    passToSub(){
      return _.assign(this.passToSubComp||{})
    }
  },
  mounted(){
    this.doConvertDate()          
    this.apiGet()
    if(this.$route.params.pageSlug){
      this.validateModuleRoleCrud()
    }
  },
    filters: {
        date(val) {
            return val ? moment(val).format("D MMM YYYY") : ""
        }
    },
  methods: {
    doDownload(value){
      if(value.file){
        return window.open(this.uploader(value.file), '_blank')
      }
    },
    openModalDokumen(item){
      this.$bvModal.show('modal-dokumen')
      this.loadingDokumen = true

      Gen.apiRest('/get/' + this.modulePage + '/get-document', {
        params: {aranr_id: item.aranr_id, aranr_aranres_id: item.aranr_aranres_id}
      }).then(res => {
        this.loadingDokumen = false
        this.dokumen = res.data.data
      }).catch(err => {
        this.loadingDokumen = false
        this.$swal({
          icon: 'error',
          text: err.response.data.message || "Terjadi Suatu Kesalahan!"
        }).then(() => {
          this.$bvModal.hide('modal-dokumen')
        })
      })

    },
    doFill(){
      this.doFilter()
    },
    doConvertDate(){
      if(this.dateRange.startDate){
        this.filter.startDate = moment(this.dateRange.startDate).format('YYYY-MM-DD')
      }
      if(this.dateRange.endDate){
        this.filter.endDate = moment(this.dateRange.endDate).format('YYYY-MM-DD')
      }
    },
    doResetData(){
        this.doReset()
        this.dateRange =  {
            startDate: null,
            endDate: null,
        }
    },
    updateValues(e){
        this.doConvertDate()
        this.doFill()
    },
    doSearch: _.debounce(function(){
      this.doFilter()
    },1000),
  },
  watch:{
    $route(){
      this.apiGet()
    },
    '$route.params.pageSlug':function(){
      this.$set(this,'filter',{})
    }
  }
}
</script>